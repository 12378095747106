import React from "react";
import { Navbar } from "../../Navbar/Navbar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Home.css";
import HomeData from "../../../Data/HomeData.json";
import CommonData from '../../../Data/CommonData.json'
import { FaRegCircleCheck } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import { Card } from "../Features/Card";
import { Footer } from "../../Footer/Footer";

export const Home = () => {
  console.log("HOME", HomeData);

  const handleReadmorebtn = () => {
    console.log("CLICKED BTN");
  };

  return (
    <div>
      <Navbar />
      {/*Carousel Image with static text*/}
      <div className="carousel-card">
        <div className="col-lg-6 col-md-6 container-fluid">
          <div className="left-h">
            <div className="text1">
              <span>{HomeData.HomeData.text1}</span>
            </div>
            <div className="text2">
              <span>{HomeData.HomeData.text2}</span>
            </div>
            <div className="readmore-btn">
              <buttons className="btn">Read more</buttons>
              <FaArrowRight className="arrow-icon" />
            </div>
          </div>
        </div>

        {/* Carousel Image */}
        <div className="col-lg-6">
          <div className="right-h">
            <Carousel autoPlay={true} interval={1000} infiniteLoop={true}>
              {HomeData.HomeData.Carousel.map((slide, index) => (
                <div key={index} className="carousel">
                  <img src={slide} />
                </div>
              ))}
            
            </Carousel>
          </div>
        </div>
      </div>

      {/* Middle Card */}
      <div className="home-section2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-7">
              <div className="home-sec2-img">
                <img src={HomeData.HomeData.Container2.centerimg} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="home-sec2-content">
                <h2>{HomeData.HomeData.Container2.CardText}</h2>
                <ul>
                    {HomeData.HomeData.Container2.CardList.map((item, index) => (
                      <li key={index}>
                        <FaRegCircleCheck style={{ color: "#ebc400" }} />
                        {item}
                     </li> 
                    ))}
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="services" class="services sections-bg">
        <div class="container"  data-aos="fade-up">
          <div class="Features-header">
            <h2>{HomeData.Container3.header}</h2>
            <p>
              {HomeData.Container3.subHead}
            </p>
          </div>
          <div class="row gy-4">
            {
              HomeData.Container3.cardItems.map((item, index) => (
                <Card
                 icon={item.icon}
                  title={item.title}
                  para={item.para}
                  />
              ))}
            
            </div>
       
        </div>
      </section>

      <div class="homepage-section4">
        <div class="container col-sm">
          <div class="row">
            <div class="contact-card col-lg-6">
            <h2>{CommonData.contactcard.title}</h2>
              <p>{CommonData.contactcard.text}</p>
            </div>
            <div class="contact-card-button col-lg-6">
              <form
                action="#"
                class="form-search d-flex align-items-stretch mb-3"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Mobile"
                />
                <button type="submit" class="btn btn-primary">
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
