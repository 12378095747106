import React from 'react'
import { Navbar } from '../../Navbar/Navbar'
import { Footer } from "../../Footer/Footer";
import './About.css'
import CommonData from '../../../Data/CommonData.json'
import AboutUsData from '../../../Data/AboutUsData.json'


export const AboutUs = () => {
  return (
    <div>
      <Navbar />

      <div className="about-section1">
        <div className="container-fluid">
          <div className="row">
            <div className="abt-sec1-img col-lg-6">
              <img src={AboutUsData.aboutUs.img} />
            </div>
            <div className="abt-sec1-content col-lg-6">
              {/* <h3>About us</h3> */}
              <h2>About <span>{AboutUsData.aboutUs.name}</span></h2>
              <p>{AboutUsData.aboutUs.para1}</p>
              <p>{AboutUsData.aboutUs.para2}</p>
              <p>{AboutUsData.aboutUs.para3}</p>
              <p>{AboutUsData.aboutUs.para4}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="about-section2">
        <div class="container">
          <div class="about-card-title">
            {/* <h3>FEATURES</h3> */}
            <h2>{AboutUsData.aboutUs.Container2.head}</h2>
            <p>{AboutUsData.aboutUs.Container2.subhead}</p>
          </div>
          <div className="row">
            {AboutUsData.aboutUs.Container2.cardItems.map((item, index) => (
              <div class="col-lg-4 Aboutcard">
              <div class="abt-card-item">
                <div class="abt-card-icon">
                  <img src={item.image} alt="" />
                </div>
                  <h3>{item.title}</h3>
                  <p>{item.para}</p>
              </div>
            </div>
            ))}
          </div>  
        </div>
      </div>

      <div class="homepage-section4">
        <div class="container">
          <div class="row">
            <div class="contact-card col-lg-6">
             <h2>{CommonData.contactcard.title}</h2>
              <p>{CommonData.contactcard.text}</p>
            </div>
            <div class="contact-card-button col-lg-6">
              <form
                action="#"
                class="form-search d-flex align-items-stretch mb-3"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mobile No"
                />
                <button type="submit" class="btn btn-primary">Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
