import React from 'react'
import "./Servicecard.css"

const ServicecardComp = (abt_service) => {
  return (
    
          <div class="features-service-item col-lg-6">
            <img src={abt_service.icon} alt="" />
            <h3>{abt_service.title}</h3>
            <p>{abt_service.para}</p>
          </div>

  )
};

export default ServicecardComp;
