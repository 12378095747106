import React from 'react'
import { Link } from "react-router-dom";
import { Navbar } from '../../Navbar/Navbar'
import { Footer } from "../../Footer/Footer";
import "./Pricing.css"
import CommonData from '../../../Data/CommonData.json'
import PricingData from '../../../Data/PricingData.json'

export const Pricing = () => {
  return (
    <div>
      <Navbar />
      
      <section id="pricing" class="pricing">
      <div class="container">

        <div class="section-title">
            <h2>{PricingData.pricing.head}</h2>
          <p>{PricingData.pricing.subtext}</p>
        </div>

        <div class="row">

          <div class="col-lg-4 col-md-6">
            <div class="box">
                <h3>{PricingData.pricing.subhead1}</h3>
              {/* <h4><sup>$</sup>0<span> / month</span></h4> */}
                {/* {PricingData.pricing.mini.map((miniItem, index) => (
                  <ul key={index}>
                    <li>{miniItem}</li>
                </ul>
              ))} */}
                <ul>
                {PricingData.pricing.mini.map((miniItem, index) => (
                    <li>{miniItem}</li>
              ))}
                {/* <li class="na">Pharetra massa</li> */}
                {/* <li class="na">Massaultricies mi</li> */}
              </ul> 
              <div class="btn-wrap">
                <Link to={"/contact"}><p class="btn-buy">Enquiry Now</p></Link>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
            <div class="box featured">
            <span class="advanced">Advanced</span>
              <h3>{PricingData.pricing.subhead2}</h3>
              {/* <h4><sup>$</sup>19<span> / month</span></h4> */}
              <ul>
              {PricingData.pricing.essential.map((essentialItem, index) => (
                    <li>{essentialItem}</li>
              ))}

                {/* <li class="na">Massa ultricies mi</li> */}
              </ul>
              <div class="btn-wrap">
              <Link to={"/contact"}><p class="btn-buy">Enquiry Now</p></Link>
              </div>
            </div>
          </div>

          {/* <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
            <div class="box">
              <h3>Developer</h3> */}
              {/* <h4><sup>$</sup>29<span> / month</span></h4> */}
              {/* <ul>
                <li>Aida dere</li>
                <li>Nec feugiat nisl</li>
                <li>Nulla at volutpat dola</li>
                <li>Pharetra massa</li>
                <li>Massa ultricies mi</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" class="btn-buy">Buy Now</a>
              </div>
            </div>
          </div> */}

          {/* <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box">
              <span class="advanced">Advanced</span>
              <h3>Ultimate</h3>
              <h4><sup>$</sup>49<span> / month</span></h4>
              <ul>
                <li>Aida dere</li>
                <li>Nec feugiat nisl</li>
                <li>Nulla at volutpat dola</li>
                <li>Pharetra massa</li>
                <li>Massa ultricies mi</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" class="btn-buy">Buy Now</a>
              </div>
            </div>
          </div> */}

        </div>

      </div>
    </section>

    <div class="homepage-section4">
        <div class="container">
          <div class="row">
            <div class="contact-card col-lg-6">
              <h2>{CommonData.contactcard.title}</h2>
              <p>{CommonData.contactcard.text}</p>
            </div>
            <div class="contact-card-button col-lg-6">
              <form
                action="#"
                class="form-search d-flex align-items-stretch mb-3"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mobile No"
                />
                <button type="submit" class="btn btn-primary">Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    <Footer />
    </div>

  )
}
