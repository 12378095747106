import { Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Link } from "react-router-dom";
import './Navbar.css'
import NavbarData from '../../Data/NavbarData.json'

export const DrawCamp = () => {
    const [open, setopen] = useState(false)
  return (
    <>
        <Drawer open={open} onClose={() =>setopen(false)} classes={{paper:"drawer-width"}}>
          <List className="drawer" >
          <ListItemButton>
            <ListItemIcon>
            <ListItemText>
            <div className="moble-menu">
            {/* <Link to={"/"}> <img className="nav-logo" src={Logo} /> </Link> Funny thing is that the one on stackbliz works oh */}
            <Link to={"/"}><img className="nav-logo2" src={NavbarData.logo} /></Link>
               <Link to={"/aboutus"}>About us</Link>
               <Link to={"/features"}>Features</Link>
               <Link to={"/package"}>Package</Link>
               <Link to={"/faq"}>FAQ</Link>
               <Link to={"/contact"}>Contact </Link>
            </div>
            </ListItemText>
            </ListItemIcon>
        </ListItemButton>
       </List>
       </Drawer>

        <IconButton onClick={()=>setopen(!open)}>
            
        <MenuOutlinedIcon />
        </IconButton>
    </>
  )
}
