import React from 'react'
import Bootstarp from '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Bootstarpjs from '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import {
   createBrowserRouter,
   RouterProvider,
} from "react-router-dom"
import { Home } from './Components/Pages/Home/Home'
import { AboutUs } from './Components/Pages/AboutUs/AboutUs'
import { Features } from './Components/Pages/Features/Features'
import { Pricing } from './Components/Pages/Pricing/Pricing'
import { Contact } from './Components/Pages/Contact/Contact'
import  Terms  from './Components/Pages/Condition/Condition'
import Faq from './Components/Pages/FAQ/FAQ'
import Privacypolicy from './Components/Pages/Privacypolicy/Privacypolicy'

export const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />
    },
    {
      path: 'aboutus',
      element: <AboutUs />
    },    
    {
      path: 'features',
      element: <Features />
    },    
    {
      path: 'pricing',
      element: <Pricing />
    }, 
    {
      path: 'faq',
      element: <Faq />
    },   
    {
      path: 'contact',
      element: <Contact />
    },
    {
      path: 'Terms',
      element: <Terms />
    },
    {
      path: 'Privacypolicy ',
      element: <Privacypolicy  />
    }
  ]);
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  )
}
export default App