import React from 'react'
import { Navbar } from '../../Navbar/Navbar'
import { Footer } from '../../Footer/Footer'
import Accordion from 'react-bootstrap/Accordion';
import CommonData from '../../../Data/CommonData.json';
import "./FAQ.css";
import FaqData from '../../../Data/FaqData.json';

const Faq = () => {
  
  const faqsArray = Object.entries(FaqData.faq.faqs).map(([question, answer]) => ({ question, answer }));

  const collapse = FaqData.faq.collapse === "true";

  return (
    <div>
      <Navbar />

      <div className="faq-section1">
        <div className="container">
        <div className="row">  
        <div className="col-lg-6">
        <Accordion defaultActiveKey={collapse ? "0" : null}>
                <h3>{FaqData.faq.head}</h3>
                {faqsArray.map((faq, index) => (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body>{faq.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
    </div>
    <div className="faq-img col-lg-6">
      <img src={FaqData.faq.img} alt="" />
    </div>
    </div>
    </div>
    </div>
       
    <div class="homepage-section4">
        <div class="container">
          <div class="row">
            <div class="contact-card col-lg-6">
              <h2>{CommonData.contactcard.title}</h2>
              <p>{CommonData.contactcard.text}</p>
            </div>
            <div class="contact-card-button col-lg-6">
              <form
                action="#"
                class="form-search d-flex align-items-stretch mb-3"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mobile"
                />
                <button type="submit" class="btn btn-primary">Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />

    </div>
  )
}

export default Faq;
