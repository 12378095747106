import React from "react";
import "../Footer/Footer.css";
import FooterData from "../../Data/FooterData.json";
import CommonData from '../../Data/CommonData.json';
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaPinterestP } from "react-icons/fa6";
import Terms from "../Pages/Condition/Condition";
import Privacypolicy from "../Pages/Privacypolicy/Privacypolicy";

export const Footer = () => {
  const scrollToComponent = (id) => {
    scroll.scrollTo(id, {
      duration: 10,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div>
      <div className="Footer">
        <div className="container-fluid">
          <div className="row">
            <div className="footer-logo col-lg-3 col-md-12">
              <Link to={"/"}>
                {" "}
                <img src={CommonData.logo} />{" "}
              </Link>
            </div>
            {/* <div className="Footer-links footer-empty col-lg-2 col-md-4">
                       <h2>Links</h2>
                      <ul>
                      <li><Link to={"/"}>Home</Link></li>
                      <li><Link to={"/aboutus"}>About us</Link></li>
                      <li><Link to={"/features"}>Features</Link></li>
                      <li><Link to={"/pricing"}>Package</Link></li>
                      <li><Link to={"/contact"}>Contact </Link></li>
                      </ul> 
                  </div> */}
            <div className="Footer-links col-lg-2 col-md-3 col-sm-4">
              <h2>{FooterData.linktext}</h2>
              <ul>
                <li>
                  {FooterData.Footers.navBarItems.map((item, index) => (
                    <li key={index}>
                    <Link to={item.url}>
                      {item.label}
                      </Link>
                    </li>
                  ))}
                </li>
              </ul>
            </div>  
            <div className="footer-contact col-lg-5 col-md-5">
              <h2>{FooterData.contactus}</h2>
              <ul>
                <li>
                  <a href="tel:+919943114028">{FooterData.mobile}</a>
                </li>
                <li>
                  <a href="mailto:byzero.co@gmail.com">{FooterData.email}</a>
                </li>
                {/* <li>15, Muthuvellappa street Kaikallan thottam, Erode, Tamil Nadu 638001</li> */}
                <div className="social-links">
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF />
                  </a>
                  <a
                    href="https://twitter.com/?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href="https://www.linkedin.com/feed/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedinIn />
                  </a>
                  <a
                    href="https://in.pinterest.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaPinterestP />
                  </a>
                </div>
              </ul>
            </div>
          </div>
          <div className="row copyright&condition">
            <div className="copyright col-lg-6 col-md-6">
              <p>{FooterData.copy}</p>
            </div>
            <div className="condition col-lg-6 col-md-6">
              <ul>
                <li>
                  <Link onClick={() => scrollToComponent("terms")}>
                  {FooterData.terms}
                  </Link>
                </li>
                <li>|</li>
                <li>
                  <Link onClick={() => scrollToComponent("privacy")}>
                  {FooterData.privacy}{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
