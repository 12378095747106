import React from 'react'
import './Card.css'

export const Card = (box) => {
  
  return (
         <div class="col-lg-4 col-md-4 services">
            <div class="service-item  position-relative">
              <div class="icon">
                <img src={box.icon} alt="" />
              </div>
              <h3>{box.title}</h3>
              <p>{box.para}</p>
              {/* <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a> */}
            </div>
          </div>

  )
}
export default Card