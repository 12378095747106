import React, { useState } from "react";
import { Navbar } from "../../Navbar/Navbar";
import { Footer } from "../../Footer/Footer";
import "./Contact.css";
import ContactData from '../../../Data/ContactData.json'
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Snackbar } from "@mui/material";

const useStyles = makeStyles(() => ({
  textField: {
    width: "100%",

    "@media (max-width: 760px)": {
      width: "80%",
    },
    "@media (max-width: 1440px)": {
      width: "100%",
    },
  },
}));

export const Contact = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // const [nameFocused, setNameFocused] = useState(false);
  // const [emailFocused, setEmailFocused] = useState(false);
  // const [mobileFocused, setMobileFocused] = useState(false);
  // const [messageFocused, setMessageFocused] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickAway") {
      return;
    }
    setOpen(false);
  };

  const handleCallUs = () => {
    const phoneNumberInput = document.getElementById("phone");
    phoneNumberInput.focus();
  };

  const handleEmailUs = () => {
    window.location.href = "mailto:info@example.com";
  };

  return (
    <div>
      <Navbar />
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            {/* <h2>Contact</h2> */}
            <p>{ContactData.contact.head}</p>
          </header>

          <div class="row gy-4">
            <div class="col-lg-6">
              <div class="row gy-4">
                {/* <div class="col-md-6">
          <div class="info-box">
            <img src={location} alt="" />
            <h3>Address</h3>
            <p>A108 Adam Street, New York, NY 535022</p>
          </div>
        </div> */}
                <div class="col-md-6">
                  <div class="info-box" onClick={handleCallUs}> 
                    <img src={ContactData.contact.mobileimg} alt="" />
                    <h3>{ContactData.contact.calltxt}</h3>
                    <p>{ContactData.contact.mobile}</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box" onClick={handleEmailUs}>
                    <img src={ContactData.contact.mailimg} alt="" />
                    <h3>{ContactData.contact.emailtxt}</h3>
                    <p>{ContactData.contact.email}</p>
                  </div>
                </div>
                {/* <div class="col-md-6">
          <div class="info-box">
            <img src={clock} alt="" />
            <h3>Open Hours</h3>
            <p>9:00AM - 05:00PM</p>
          </div>
        </div> */}
              </div>
            </div>

            <div class="col-lg-6">
              <form
                action="forms/contact.php"
                method="post"
                class="php-email-form"
              >
                <div class="row gy-4">
                  <div class="col-md-6">
                    {/* <input
                      type="text"
                      name="name"
                      class={`form-control ${nameFocused ? 'focused' : '' }`}
                      placeholder="Name"
                      required
                      onFocus={() => setNameFocused(true)}
                      onBlur={() => setNameFocused(false)}
                    /> */}
                    <TextField
                      label={ContactData.contact.placeholder1}
                      variant="outlined"
                      // className='name-field'
                      InputLabelProps={{
                        style: {
                          fontFamily: 'Outfit',
                          fontWeight:500,
                        }
                      }}
                      className={classes.textField}
                    />
                  </div>

                  <div class="col-md-6 ">
                    {/* <input
                      type="email"
                      class={`form-control ${emailFocused ? 'focused' : '' }`}
                      name="email"
                      placeholder="Email"
                      required
                      onFocus={() => setEmailFocused(true)}
                      onBlur={() => setEmailFocused(false)}
                    /> */}
                    <TextField
                      label={ContactData.contact.placeholder2}
                      variant="outlined"
                      //className='email-field'
                      InputLabelProps={{
                        style: {
                          fontFamily: 'Outfit',
                          fontWeight:500,
                        }
                      }}
                      className={classes.textField}
                    />
                  </div>

                  <div class="col-md-12">
                    {/* <input
                      type="tel"
                      class={`form-control ${mobileFocused ? 'focused' : ''}`}
                      name="tel"
                      placeholder="Mobile"
                      required
                      onFocus={() => setMobileFocused(true)}
                      onBlur={() => setMobileFocused(false)}
                    /> */}
                    <TextField
                      label={ContactData.contact.placeholder3}
                      variant="outlined"
                      //className='mobile-field'
                      InputLabelProps={{
                        style: {
                          fontFamily: 'Outfit',
                          fontWeight: 500,
                        }
                      }}
                      className={classes.textField}
                    />
                  </div>

                  <div class="col-md-12">
                    {/* <textarea
                      class={`form-control ${messageFocused ? 'focused' : ''}`}
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required
                      onFocus={() => setMessageFocused(true)}
                      onBlur={() => setMessageFocused(false)}
                    ></textarea> */}
                    <TextField
                      label={ContactData.contact.placeholder4}
                      variant="outlined"
                      multiline
                      rows={6}
                      //className='message-field'
                      InputLabelProps={{
                        style: {
                          fontFamily: 'Outfit',
                          fontWeight:500,
                        }
                      }}
                      className={classes.textField}
                    />
                  </div>

                  <div class="col-md-12 text-center">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                    <button type="submit" onClick={() => setOpen(true)}>
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <Snackbar
                message="Your message has been sent. Thank you!"
                autoHideDuration={800}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
