import React, {useState} from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import NavbarData from '../../Data/NavbarData.json';
import CommonData from '../../Data/CommonData.json';
import { DrawCamp } from "./DrawCamp";

export const Navbar = () => {
  
  console.log('NAVI LOGO', NavbarData.logo)
  
  return (
    <div className =" navbar-container">
      <div className="container-fluid">
        <div className="row header-mobile">
          <div className="col-lg-4 col-md-4">
            <div className="nav-logo">
              <Link to={"/"}> <img src={CommonData.logo} /> </Link>
              
            </div>
          </div>
        
          <div className="col-lg-8 col-md-8">
               <div className="navbar">
               {NavbarData.headers.navBarItems.map((item, index) => (
                <Link key={index} to={item.url}>{item.label}</Link>
              ))}
             </div>
             <DrawCamp />
          </div>
        </div>
      </div>
      
    </div>

  );
};
