import React from 'react'
import { Navbar } from '../../Navbar/Navbar'
import { Footer } from '../../Footer/Footer'
import "./Features.css"
import FeaturesData from '../../../Data/FeaturesData.json'
import CommonData from '../../../Data/CommonData.json'
import ServicecardComp from "../Servicecard/ServicecardComp";

export const Features = () => {
  return (
    <div>
      <Navbar />

      <div className="features-section1">
        <div className="container">
          <div className="row">
            <div className="fearures-title">
              <h2>Features</h2>
            </div>
          </div>
          <div className="row">
            <div className="features-sec1-img col-lg-6">
              <img src={FeaturesData.features.image1} alt="" />
            </div>
            <div className="features-sec1-content col-lg-6">
              <h2>{FeaturesData.features.customer}<span><small>{FeaturesData.features.pro}</small></span></h2>
              <p>{FeaturesData.features.customertext}</p>
              <div className="feartures-service row">
                {FeaturesData.features.customerCard.map((item, index) => (
                  <ServicecardComp icon={item.icon} title={item.title} 
                    
                   para={item.para}
                  />
              ))}
              </div>
               {/* <div className="feartures-service row">
                  <ServicecardComp icon={user} title={"User Friendly Interface"} 
                   para={"At OUTLAY, we believe in simplicity. Our user-friendly interface ensures that you can navigate the app effortlessly. From browsing a variety of mouthwatering pastries to selecting the perfect cake for your celebration, our intuitive design makes it a breeze."}
                  />
                  <ServicecardComp icon={price} title={"Payment Gateway integration"} 
                   para={"OUTLAY prioritizes your security. Choose from a variety of payment options, including credit/debit cards and secure digital wallets, for a hassle-free and secure transaction."}
                  />
                  <ServicecardComp icon={cake} title={"Customized cake"} 
                   para={""}
                  />
                  <ServicecardComp icon={addon} title={"Addon option"} />
                  <ServicecardComp icon={whatsapp} title={"Checkout with WhatsApp Order"} />
               </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="features-section1">
        <div className="container">
          <div className="row">
            <div className="features-sec1-img col-lg-6">
              <img src={FeaturesData.features.image2} alt="" />
            </div>
            <div className="features-sec1-content col-lg-6">
              <h2>{FeaturesData.features.admin} <span><small>{FeaturesData.features.pro}</small></span></h2>
              <p>{FeaturesData.features.admintext}</p>
              <div className="feartures-service row">
                {FeaturesData.features.adminCard.map((item, index) => (
                  <ServicecardComp icon={item.icon} title={item.title} 
                   para={item.para}
                  />
              ))}
              </div>
               {/* <div className="feartures-service row">
                  <ServicecardComp icon={order} title={"Advanced Order Insights"} 
                   para={"Gain valuable insights into your bakery's orders with our advanced analytics, empowering informed decision-making for optimized operations."}
                  />
                  <ServicecardComp icon={dashboard} title={"Single Dashboard to view operations and performance"} 
                   para={"Simplify management with a centralized dashboard, offering a comprehensive view of your bakery's performance and daily operations."}
                  />
                  <ServicecardComp icon={product} title={"Product Management"} 
                   para={"Effortlessly manage your bakery's product catalog, ensuring that your offerings are up-to-date and appealing to your customers."}
                  />
                  <ServicecardComp icon={price} title={"Price management"} 
                   para={"Set and adjust product prices easily, ensuring your bakery remains competitive while maximizing profitability."}
                  />
                  <ServicecardComp icon={discount} title={"Discounts, Offers"} 
                   para={"Attract more customers by creating enticing discounts and special offers, boosting sales and fostering customer loyalty."}
                  />
                  <ServicecardComp icon={reports} title={"Reports"} 
                   para={"Access detailed reports to track sales, analyze trends, and make data-driven decisions to enhance your bakery's overall efficiency and profitability."}
                  />
               </div> */}
            </div>
          </div>
        </div>
      </div>

      <div class="homepage-section4">
        <div class="container">
          <div class="row">
            <div class="contact-card col-lg-6">
            <h2>{CommonData.contactcard.title}</h2>
              <p>{CommonData.contactcard.text}</p>
            </div>
            <div class="contact-card-button col-lg-6">
              <form
                action="#"
                class="form-search d-flex align-items-stretch mb-3"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Email here"
                />
                <button type="submit" class="btn btn-primary">Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </div>
  )
}
